@tailwind base;
@tailwind components;
@tailwind utilities;


@layer component {
    .hamburgerButton.open span:first-child {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        top: 14px;      
    }
    .hamburgerButton.open span:nth-child(2) {
      opacity: 0;
    }
    .hamburgerButton.open span:last-child {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        top: -8px;
    }
    }
    